@tailwind base;
@tailwind components;
@tailwind utilities;

/* Additional global styles can go here */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

* {
  box-sizing: border-box;
}

.react-calendar-timeline .rct-header-root {
  background-color: white !important;
  border-bottom: 1px solid #e5e7eb !important;
}

.react-calendar-timeline .rct-sidebar {
  border-right: 1px solid #e5e7eb !important;
  background-color: white !important;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
  border-left: 1px solid #e5e7eb !important;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl {
  border-bottom: 1px solid #f3f4f6 !important;
}

.react-calendar-timeline .rct-dateHeader {
  border-left: 1px solid #e5e7eb !important;
  color: #6b7280 !important;
  font-size: 0.75rem !important;
}